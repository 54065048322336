import React from 'react';
import styles from '../../pages/Upload.module.css';

const OptionGroup = ({ options, activeOption, activeOptions, onChange, multiple }) => {
  const handleClick = (option) => {
    if (multiple) {
      const updatedOptions = activeOptions.includes(option)
        ? activeOptions.filter((item) => item !== option)
        : [...activeOptions, option];
      onChange(updatedOptions);
    } else {
      onChange(option === activeOption ? null : option);
    }
  };

  return (
    <div className={styles.optionGroup}>
      {options.map((option) => (
        <button
          key={option}
          className={
            multiple
              ? activeOptions.includes(option)
                ? styles.activeOption
                : styles.inactiveOption
              : option === activeOption
              ? styles.activeOption
              : styles.inactiveOption
          }
          onClick={() => handleClick(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default OptionGroup;
