import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { verifyUserAccount } from "../API/api";
import styles from "./VerifyUser.module.css";

function VerifyUser() {
  const [statusMessage, setStatusMessage] = useState("Verifying...");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Extract 'hash' from the URL query parameters
  const hashNameandEmail = searchParams.get("hash");

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const result = await verifyUserAccount(hashNameandEmail);
        if (result) {
          setStatusMessage("Verification Successful! Redirecting to login...");
          setTimeout(() => navigate("/Login"), 3000);
        }
      } catch (error) {
        setStatusMessage(
          "Verification Failed. Please contact us at admin@lenorai.com"
        );
      }
    };

    if (hashNameandEmail) {
      verifyUser();
    } else {
      setStatusMessage("Invalid verification link.");
    }
  }, [hashNameandEmail, navigate]);

  return (
    <div className={styles["verify-user-container"]}>
      <h2>{statusMessage}</h2>
    </div>
  );
}

export default VerifyUser;