import { useState } from "react";
import styles from "../../pages/QuestionUpload.module.css";
import { FileUploader } from "../question_upload_page/FileUploader";
import { uploadNewQuestion } from "../../API/api";

export default function EssayPromptModal(props) {
    const {onClose} = props;
    // model status
    // const ESSAY_PROMPT_MODAL_STATES = ["main", "manually_create_question", "upload_existing_question"];
    const userId = localStorage.getItem("uid"); 
    const [questionTitle, setQuestionTitle] = useState("");
    const [questionBody, setQuestionBody]  = useState("");
    const [modalState, setModalState] = useState("main");
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const removeUploadFiles = (index) => {
        const updatedFiles = uploadingFiles.filter((_, i) => i !== index);
        setUploadingFiles(updatedFiles);
    };
    const goBackToMain = () => {
        setModalState("main")
    }
    const uploadFile = async ()=>{
        const formData = new FormData();
        setIsPending(true);
        try {
            for (let i = 0; i < uploadingFiles.length; i++) {
                formData.append("files", uploadingFiles[i]);
            }
            formData.append("topic", questionTitle);
            formData.append("instruction", questionBody);
            await uploadNewQuestion(userId, formData);
            // after successfully send the data
            setIsPending(false);
            onClose();
        } catch (error) {
            console.error("Error in uploadFiles:", error);
            throw error;
        }

    }

    const canSubmit = questionTitle.trim() !== "" && uploadingFiles.length !== 0;

    return (
        <div className={styles.essayPromptModalOverlay}>
        <div className={styles.essayPromptContent}>
        <button className={styles.closeButton} onClick={onClose}>
            {" "}
            &times;{" "}
        </button>
        {   /* Main page */
            modalState === "main" && 
            <div style={{display: "flex", flexDirection: "column", gap: "30px", padding: "40px 40px"}}>
                <button onClick={() => setModalState("upload_existing_question")} className={styles.selectButton}>
                    Upload Existing Question
                </button>
                <button onClick={() => setModalState("manually_create_question")} className={styles.selectButton}>
                    Manually Create
                </button>
            </div>
        }
        {   /* Manually create question */
            modalState === "manually_create_question" && 
            <div>
                <div>
                    <div> ESSAY QUESTION TITLE (FOR IDENTIFICATION) </div>
                    <input type="text" placeholder="Title" value={questionTitle} onChange={(e) => setQuestionTitle(e.target.value)} className={styles.inputEssayTitle}/>
                </div>
                <div>
                    <div> ESSAY QUESTION BODY  </div>
                    <textarea type="text" placeholder="Prompt" value={questionBody} onChange={(e) => setQuestionBody(e.target.value)} className={styles.inputEssayPrompt}/>
                </div>
                <div>
                    <div> IMAGES (FOR IMAGE PROMPTED ESSAYS)  </div>
                    <FileUploader
                        setUploadingFiles={setUploadingFiles}
                        uploadingFiles={uploadingFiles}
                        removeUploadFiles={removeUploadFiles}
                    />
                </div>
                <button className={styles.cancelButton} onClick={goBackToMain}>Cancel</button>
                <button className={styles.submitButton} onClick={uploadFile}>Create</button>
            </div>
        }
        {   /* Upload existing question */
            modalState === "upload_existing_question" && 
            <div>
                <div>
                    <div> ESSAY QUESTION TITLE (FOR IDENTIFICATION) </div>
                    <input type="text" placeholder="Title" value={questionTitle} onChange={(e) => setQuestionTitle(e.target.value)} className={styles.inputEssayTitle}/>
                </div>
                <div>
                    <div> ESSAY QUESTION FILES (.pdf, .jpg, .png, .docx) </div>
                    <FileUploader
                        setUploadingFiles={setUploadingFiles}
                        uploadingFiles={uploadingFiles}
                        removeUploadFiles={removeUploadFiles}
                    />
                </div>
                <button className={styles.cancelButton} onClick={goBackToMain}>Cancel</button>
                <button className={styles.submitButton} onClick={uploadFile} disabled={!canSubmit}>{!isPending ? "Create" : "Pending"}</button>
            </div>
        }
        
        </div>
        </div>
    )
}