/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "../../pages/GradedEssay.module.css";
import leftArrowIcon from "../../assets/left-arrow-with-circle.svg";
import rightArrowIcon from "../../assets/right-arrow-with-circle.svg";
import { filterAIOutput } from "../../utils/filterAIOutputt";
import { updateDocument , setFeedbackAsModel} from "../../API/api";

const Carousel = (props) => {
  const { data } = props;

  const summaryOutput = filterAIOutput(data?.processedOutputAI, "Summary");
  const feedbackOutput = filterAIOutput(data?.processedOutputAI, "Feedback");
  const strengthOutput = filterAIOutput(data?.processedOutputAI, "Strength");
  const weaknessOutput = filterAIOutput(data?.processedOutputAI, "Weakness");
  const spellingErrorOutput = filterAIOutput(data?.processedOutputAI, "Spelling Errors and Unclear Handwriting");
  const punctuationErrorOutput = filterAIOutput(data?.processedOutputAI, "Punctuation");
  const grammarErrorOutput = filterAIOutput(data?.processedOutputAI, "Grammar and Syntax Errors") || filterAIOutput(data?.processedOutputAI, "Grammar and Sentence Structure");
  const improvementsOutput = filterAIOutput(data?.processedOutputAI, "Improvements");
  const { id } = useParams();
  const formatText = (text) => {
    // return text.replace(/<br>/g, "\n");
    return text.replace(/<br> /, "").replace(/<br>/g, "\n");
  };

  // Check box for if all feedback in carousel are good 
  const [isFeedbackApproved, setIsFeedbackApproved] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsFeedbackApproved(e.target.checked);
    setFeedbackAsModel(e.target.checked, id);
    console.log(`Set to ${e.target.checked}`);
  };

  const summary = (summaryOutput[1] ? formatText(summaryOutput[1]) : "None");
  const feedback = (feedbackOutput[1] ? formatText(feedbackOutput[1]) : "None");
  const strength = (strengthOutput[1] ? formatText(strengthOutput[1]) : "None");
  const weakness = (weaknessOutput[1] ? formatText(weaknessOutput[1]) : "None");
  const spellingError = (spellingErrorOutput[1] ? formatText(spellingErrorOutput[1]) : "None");
  const punctuationError = (punctuationErrorOutput[1] ? formatText(punctuationErrorOutput[1]) : "None");
  const grammarError = (grammarErrorOutput[1] ? formatText(grammarErrorOutput[1]) : "None");
  const revision = (improvementsOutput[1] ? formatText(improvementsOutput[1]) : "None");

  const [items, setItems] = useState([summary, feedback, strength, weakness, spellingError, punctuationError, grammarError, revision]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentText, setCurrentText] = useState(items[0]);

  useEffect(() => {
    setCurrentText(items[currentIndex]);
  }, [currentIndex]);

  useEffect(() => {
    setIsFeedbackApproved(data?.isModel)
  }, [])

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    setIsEditing(false);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  // The older function to display title inside the carousel
  const findKey = () => {
    let key = "";
    switch (currentIndex) {
      case 0:
        key = "Summary";
        break;
      case 1:
        key = "Feedback";
        break;
      case 2:
        key = "Strength";
        break;
      case 3:
        key = "Weakness";
        break;
      case 4:
        key = "Errors";
        break;
      case 5:
        key = "Revision";
        break;
      default:
        break;
    }

    return key;
  }

  // The newer function to display title on the top of the carousel
  const getTitle = (index) => {
    switch (index) {
      case 0:
        return "Summary";
      case 1:
        return "Feedback";
      case 2:
        return "Strength";
      case 3:
        return "Weakness";
      case 4:
        return "Spelling Errors";
      case 5:
        return "Punctuation";
      case 6:
        return "Grammar and Sentence Structure";
      case 7:
        return "Improvements";
      default:
        return "";
    }
  };
  

  const handleTextChange = (e) => {
    setCurrentText(e.target.value);
  };

  const handleBlur = async () => {
    setIsEditing(false);

    let key = getTitle(currentIndex);

    const currentTextValue = currentText;
    items[currentIndex] = currentTextValue;

    try {
      const valueExisted = data?.processedOutputAI.find((prop) => {
        return prop[0].trim() === key;
      });

      let newProcessedOutputAI = [];
      if (valueExisted) {
        newProcessedOutputAI = data?.processedOutputAI.map((prop) => {
          if (prop[0] === key) {
            return [key, currentTextValue];
          } else {
            return prop;
          }
        });
      } else {
        newProcessedOutputAI = [
          ...data?.processedOutputAI,
          [key, currentTextValue],
        ];
      }

      await updateDocument(data._id, {
        processedOutputAI: newProcessedOutputAI,
      });

      // Update the items state with the updated text
      const updatedItems = [...items];
      updatedItems[currentIndex] = currentTextValue;
      setItems(updatedItems);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div className={styles.sectionTitle}>
        <span>Feedback</span>
        <span className={styles.studentDetails}>
          Summary, Feedback, Strength, Weakness, Errors, Revision
        </span>
      </div>

      <div style={{ textAlign: "center" }}>
        <span className={styles.dynamicTitle}>{getTitle(currentIndex)}</span>
      </div>

      <div className={styles.carouselContainer}>
        <button className={styles.arrowButton} onClick={prevSlide}>
          <img src={leftArrowIcon} alt="Previous" />
        </button>
        <div className={styles.carouselContent}>
          {isEditing ? (
            <textarea
              className={styles.editableTextFull}
              value={currentText}
              onChange={handleTextChange}
              onBlur={handleBlur}
              autoFocus
            />
          ) : (
            <div className={styles.feedbackTextFull} onClick={handleEditClick}>
              {currentText}
            </div>
          )}
        </div>
        <button className={styles.arrowButton} onClick={nextSlide}>
          <img src={rightArrowIcon} alt="Next" />
        </button>
      </div>
      <div className={styles.checkboxContainer}>
        <label className={styles.checkboxLabel}>
          <input
            type="checkbox"
            checked={isFeedbackApproved}
            onChange={handleCheckboxChange}
            className={styles.checkboxInput}
          />
          All feedback is good
        </label>
      </div>
    </div>
  );
};

export default Carousel;
