import React from 'react';
import styles from '../../pages/GradedEssay.module.css';
import { appendFeedbackPDF } from '../../API/api';

function AppendFeedbackButton(props) {
  const {feedback, documentId} = props;

  const handleAppendFeedback = () => {
    appendFeedbackPDF(feedback, documentId);
  };

  return (
    <section className={styles.gradeSection}>
      <div className={styles.downloadButtons}>
        <button onClick={handleAppendFeedback} className={styles.downloadButton}>
          Append Feedback
        </button>
      </div>
    </section>
  );
}

export default AppendFeedbackButton;