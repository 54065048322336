import { useEffect, useState } from "react";
import styles from "../../pages/QuestionUpload.module.css";
import {FileUploader, FileURLUploader} from "../question_upload_page/FileUploader";
import { getQuestion, updateQuestion, uploadNewQuestion } from "../../API/api";

export default function EditEssayPromptModal(props) {
    const {onClose, id} = props;
    const userId = localStorage.getItem("uid"); 
    // model status
    // const ESSAY_PROMPT_MODAL_STATES = ["main", "manually_create_question", "upload_existing_question", "edit_question"];
    const [questionTitle, setQuestionTitle] = useState("");
    const [questionBody, setQuestionBody]  = useState("");
    const [modalState, setModalState] = useState("main");
    const [uploadingFiles, setUploadingFiles] = useState([]); 
    const [uploadingFilesURL, setUploadingFilesURL] = useState([]);
    const [isPending, setIsPending] = useState(false);

    const removeUploadFiles = (index) => {
        const updatedFiles = uploadingFiles.filter((_, i) => i !== index);
        setUploadingFiles(updatedFiles);
    };
    const removeUploadFilesURL =  (index) => {
        const updatedFilesURL = uploadingFilesURL.filter((_, i) => i !== index);
        setUploadingFilesURL(updatedFilesURL);
    };
    const updateEditedQuestion = async () => {
        setIsPending(true);
        const formData = new FormData();
        try {
            for (let i = 0; i < uploadingFiles.length; i++) {
                formData.append("files", uploadingFiles[i]);
            }
            console.log(uploadingFilesURL);
            formData.append("topic", questionTitle);
            formData.append("instruction", questionBody);
            formData.append("_id", id);
            formData.append("google-storage-url", uploadingFilesURL);
            await updateQuestion(userId, formData);
            setIsPending(false);
            onClose();
            // window.location.reload();
        } catch (error) {
            console.error("Error in uploadFiles:", error);
            throw error;
        }
        
    }

    const fetchQuestion = async () => {
        if (id != null) {
            const data = await getQuestion(userId, id);
            if (data !== null) {
                setQuestionTitle(data.topic);
                setQuestionBody(data.instruction);
                const googleStorageFilePaths = data.filePaths;
                console.log("Here")
                console.log(googleStorageFilePaths)
                setUploadingFilesURL(googleStorageFilePaths);
            }
        }
    }

    useEffect(() => {
        fetchQuestion();
    }, []); // fetch question only once
    return (
        <div className={styles.essayPromptModalOverlay}>
        <div className={styles.essayPromptContent}>
             <button className={styles.closeButton} onClick={onClose}>
                {" "}
                &times;{" "}
            </button>
            <div>
                <div> ESSAY QUESTION TITLE (FOR IDENTIFICATION) </div>
                <input type="text" placeholder="Title" value={questionTitle} onChange={(e) => setQuestionTitle(e.target.value)} className={styles.inputEssayTitle}/>
            </div>
            <div>
                <div> ESSAY QUESTION BODY  </div>
                <textarea type="text" placeholder="Prompt" value={questionBody} onChange={(e) => setQuestionBody(e.target.value)} className={styles.inputEssayPrompt}/>
            </div>
            <div>
                <div> ESSAY QUESTION FILE (only jpg, jpeg, png, or pdf) </div>


                {uploadingFilesURL.length >= 1 && "Already uploaded"}
                <FileURLUploader
                    uploadingFilesURL={uploadingFilesURL}
                    removeUploadFilesURL={removeUploadFilesURL}
                />
                {uploadingFiles.length >= 1 && "Upload new files"}
                <FileUploader
                    setUploadingFiles={setUploadingFiles}
                    uploadingFiles={uploadingFiles}
                    removeUploadFiles={removeUploadFiles}
                    allowedFileTypes={["jpg", "jpeg", "png", "pdf"]}
                />
            </div>
            <button className={styles.cancelButton} onClick={onClose}>Cancel</button>
            <button className={styles.submitButton} onClick={updateEditedQuestion}>{!isPending ? "Save" : "Pending"}</button>
       
        </div>
        </div>
    )
}