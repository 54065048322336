import React, { useState, useEffect } from "react";
import styles from "../../pages/GradedEssay.module.css";
import { appendFeedbackPDF, updateFeedbackChoices, fetchFeedbackChoices } from "../../API/api";
import file_download_icon from "../../assets/file-download.svg";
import loading_gif from "../../assets/loading.gif";
import { filterAIOutput } from "../../utils/filterAIOutputt";

const FEEDBACK_OPTIONS = [
  "Total Score",
  "Score Breakdown",
  "Summary",
  "Feedback",
  "Strength",
  "Weakness",
  "Spelling Errors",
  "Grammar Errors",
  "Improvements",
];

const MONGODB_FEEDBACK_MAP = new Map([
  ["feedback", "Feedback"],
  ["strength", "Strength"],
  ["weakness", "Weakness"],
  ["totalScore", "Total Score"],
  ["grammarErrors", "Grammar Errors"],
  ["scoreBreakdown", "Score Breakdown"],
  ["spellingErrors", "Spelling Errors"],
  ["improvements", "Improvements"],
  ["summary", "Summary"]
]);

function AppendFeedbackModal(props) {
  const { data, onClose } = props;
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [isFetchingChoices, setIsFetchingChoices] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const getFeedbackChoices = async () => {
      const response = await fetchFeedbackChoices();
      setIsFetchingChoices(false);
      const data = await response.json(); // parse JSON if needed
      const filteredChoices = Object.keys(data).filter(key => data[key]).map(key => MONGODB_FEEDBACK_MAP.get(key)).filter(key => key !== undefined);
      setFeedbackOptions(filteredChoices);
    };
    setIsFetchingChoices(true);
    getFeedbackChoices();
  }, []);

  if (isFetchingChoices) {
    return (
      <div className={styles.appendFeedbackModalOverlay}>
        <div className={styles.appendFeedbackContent} style={{ textAlign: "center" }}>
          <span>loading...</span>
        </div>
      </div>
    );
  }

  // determine if the save button show be in loading state
  const handleSaveClick = async () => {
    setIsSaving(true); 
    try {
      saveFeedbackPreferences(feedbackOptions);
      alert("Preference saved");
      setIsSaving(false);
    } catch (error) {
      console.error("Error while saving preferences:", error);
      alert("Saving failed. Please try again.");
      setIsSaving(false);
    }
  };

  // determine if the download button show be in loading state
  const handleDownloadClick = async () => {
    setIsLoading(true); 
    try {
      await appendFeedbackPDF(generateFeedback(feedbackOptions, data), data._id);
    } catch (error) {
      console.error("Error while downloading PDF:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div className={styles.appendFeedbackModalOverlay}>
      <div className={styles.appendFeedbackContent}>
        <span>SELECT WHAT FEEDBACK TO APPEND WHEN DOWNLOADING THE ESSAY</span>
        <button className={styles.closeButton} onClick={onClose}>
          {" "}
          &times;{" "}
        </button>
        <OptionGroup
          options={FEEDBACK_OPTIONS}
          activeOptions={feedbackOptions}
          onChange={setFeedbackOptions}
          multiple={true}
        />
        <div
          className={styles.downloadButtons}
          style={{ "marginTop": "10px", "justifyContent": "right" }} // overloading style
        >
          {/* <button
            onClick={() => saveFeedbackPreferences(feedbackOptions)}
            className={styles.downloadButton}
          >
            SAVE
          </button> */}
          <button
            onClick={handleSaveClick}
            className={`${styles.downloadButton} ${isSaving ? styles.disabledButton : ""}`}
            disabled={isSaving} // Disable button while saving
          >
            {isSaving ? (
              <img
                src={loading_gif}
                alt="Processing..."
                key="loading-gif-save"
              />
            ) : (
              "SAVE"
            )}
          </button>
          {/* <button
            onClick={() =>
              appendFeedbackPDF(
                generateFeedback(feedbackOptions, data),
                data._id,
              )
            }
            className={styles.downloadButton}
          >
            <img src={file_download_icon} alt="Download PDF" />
            PDF
          </button> */}
          <button
            onClick={handleDownloadClick}
            className={`${styles.downloadButton} ${isLoading ? styles.disabledButton : ""}`}
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? (
              <img
                src={loading_gif}
                alt="Processing..."
                key="loading-gif"
              />
            ) : (
              <img
                src={file_download_icon}
                alt="Download PDF"
                key="file-download-icon"
              />
            )}
            PDF
          </button>
        </div>
      </div>
    </div>
  );
}

// Copied from upload_page/option_group
const OptionGroup = ({
  options,
  activeOption,
  activeOptions,
  onChange,
  multiple,
}) => {
  // check whether all options have been selected or not
  const isSelectAll = () => activeOptions.length === options.length;

  const handleClick = (option) => {
    const updatedOptions = activeOptions.includes(option)
      ? activeOptions.filter((item) => item !== option)
      : [...activeOptions, option];
    onChange(updatedOptions);
  };

  const handleClickAll = () => {
    if (!isSelectAll())
      onChange(options); // Append All: select all filters
    else onChange([]); // Deselect All: toggle all filters
  };

  return (
    <div className={styles.optionGroupVertical}>
      <span>
        {"Append All    "}
        <button
          key="Append All"
          onClick={handleClickAll}
          className={isSelectAll() ? styles.activeOption : styles.inactiveOption}
          style={{ // very cheesy way to offset a button
            "position": "relative",
            "top": "-3px",
            "padding": "10px 10px"
          }}
        ></button>
      </span>
      {options.map((option) => (
        <button
          key={option}
          className={
            multiple
              ? activeOptions.includes(option)
                ? styles.activeOption
                : styles.inactiveOption
              : option === activeOption
                ? styles.activeOption
                : styles.inactiveOption
          }
          onClick={() => handleClick(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

// using data from backend to generate feedback
function generateFeedback(feedbackOptions, data) {
  let feedbackOutput = "# General Feedback\n";
  const { processedOutputAI, pointSystem } = data;

  // append score
  const maxPoint = parseInt(pointSystem.slice(0, 2));
  const totalScoreEntry = filterAIOutput(processedOutputAI, "Total Score")[1];
  const contentScoreEntry = filterAIOutput(
    processedOutputAI,
    "Content Score",
  )[1];
  const languageScoreEntry = filterAIOutput(
    processedOutputAI,
    "Language Score",
  )[1];
  if (feedbackOptions.includes("Total Score")) {
    feedbackOutput += `- Total Score: ${totalScoreEntry}/${maxPoint}\n`;
  }
  if (feedbackOptions.includes("Score Breakdown")) {
    feedbackOutput += `- Language Score: ${languageScoreEntry}/${maxPoint / 2}\n`;
    feedbackOutput += `- Content Score: ${contentScoreEntry}/${maxPoint / 2}\n`;
  }

  if (feedbackOptions.includes("Summary")) {
    const summary = filterAIOutput(processedOutputAI, "Summary")[1];
    feedbackOutput += `## Summary\n${summary}\n`;
  }
  if (feedbackOptions.includes("Feedback")) {
    const feedback = filterAIOutput(processedOutputAI, "Feedback")[1];
    feedbackOutput += `## Feedback\n${feedback.trim()}\n`;
  }
  if (feedbackOptions.includes("Strength")) {
    const strength = filterAIOutput(processedOutputAI, "Strength")[1];
    feedbackOutput += `## Strength\n${strength.trim()}\n`;
  }
  if (feedbackOptions.includes("Weakness")) {
    const weakness = filterAIOutput(processedOutputAI, "Weakness")[1];
    feedbackOutput += `## Weakness\n${weakness.trim()}\n`;
  }
  if (feedbackOptions.includes("Spelling Errors")) {
    const spellingErrors = filterAIOutput(
      processedOutputAI,
      "Spelling Errors and Unclear Handwriting",
    )[1];
    feedbackOutput += `### Spelling errors\n${spellingErrors.trim()}\n`;
  }

  if (feedbackOptions.includes("Grammar Errors")) {
    const grammarErrors = filterAIOutput(
      processedOutputAI,
      "Grammar and Sentence Structure",
    )[1];
    feedbackOutput += `### Grammar errors\n${grammarErrors.trim()}\n`;
  }

  if (feedbackOptions.includes("Improvements")) {
    const improvements = filterAIOutput(processedOutputAI, "Improvements")[1];
    feedbackOutput += `### Improvements\n${improvements.trim()}\n`;
  }

  return feedbackOutput;
}

const FEEDBACK_MAPPING = {
  "Total Score": "totalScore",
  "Score Breakdown": "scoreBreakdown",
  "Summary": "summary",
  "Feedback": "feedback",
  "Strength": "strength",
  "Weakness": "weakness",
  "Spelling Errors": "spellingErrors",
  "Grammar Errors": "grammarErrors",
  "Improvements": "improvements"
};

function saveFeedbackPreferences(feedbackOptions) {
  let feedbackChoices = {};

  for (const [option, key] of Object.entries(FEEDBACK_MAPPING)) {
    feedbackChoices[key] = feedbackOptions.includes(option);
  }

  updateFeedbackChoices(feedbackChoices);
  return true;
}

export default AppendFeedbackModal;
