import React, { useState, useEffect, useRef } from "react";
import styles from "./Upload.module.css";
import Sidebar from "../components/Sidebar";
import InputField from "../components/upload_page/InputField";
import OptionGroup from "../components/upload_page/OptionGroup";
import FileUploader from "../components/upload_page/FileUploader";
import { questionList, uploadFiles } from "../API/api";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import DropdownSelector from "../components/upload_page/DropdownSelector";

const HOST_API = process.env.REACT_APP_API_URL || "https://remarkably.ink/api";

const GradingSystem = () => {
  const navigate = useNavigate();
  const loading = useRef(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [className, setClassName] = useState("");
  const [assignmentName, setAssignmentName] = useState("");
  const [pointSystem, setPointSystem] = useState("36 Points");
  const [grade, setGrade] = useState("");
  const [essayType, setEssayType] = useState("");
  const [awardPoints, setAwardPoints] = useState([]);

  const [essayQuestion, setEssayQuestion] = useState("");
  const [essayQuestions, setEssayQuestions] = useState([]);
  const [customInstructions, setCustomInstructions] = useState("");

  const [isUploading, setIsUploading] = useState(false);

  async function updateMasterPrompt() {
    try {
      const response = await fetch(
        `${HOST_API}/masterprompt/master-prompt?className=${className}&assignmentName=${assignmentName}&pointSystem=${pointSystem}&grade=${grade}&essayType=${essayType}&awardPoints=${awardPoints.join(
          ","
        )}&customInstructions=${customInstructions}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await response.text();
    } catch (error) {
      console.error("Error fetching the master prompt:", error);
    }
  }

  useEffect(() => {
    const fetchQuestions = async () => {
      const uid = localStorage.getItem("uid");
      const res = await questionList(1, { uid });
      if (res && res.body) {
        setEssayQuestions(res.body);
      }
    };
    fetchQuestions();
  }, []);

  const handleUploadFiles = async () => {
    try {
      const selectedFiles = uploadingFiles;
      const formData = new FormData();
      formData.append("className", className);
      formData.append("assignmentName", assignmentName);
      formData.append("pointSystem", pointSystem);
      formData.append("grade", grade);
      formData.append("essayType", essayType);
      formData.append("awardPoints", awardPoints.join(","));
      formData.append("customInstructions", customInstructions);
      formData.append("userId", localStorage.getItem("uid"));
      formData.append("questionId", essayQuestion);
      console.log(essayQuestion)
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }
      const response = await uploadFiles(formData);

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    loading.current.disabled = true;
    setIsUploading(true);

    try {
      // Validate
      if (!uploadingFiles || uploadingFiles.length === 0) {
        alert("Please upload files before start grading!");
        setIsUploading(false);
        return;
      }

      await handleUploadFiles();

      navigate("/Upload-History");
    } catch (error) {
      alert("Cannot grade your essay at this time, please try again later!");
      console.log(error);
      loading.current.disabled = false;
    } finally {
      setIsUploading(false);
      loading.current.disabled = false;
    }
  };

  const removeUploadFiles = (index) => {
    const updatedFiles = uploadingFiles.filter((_, i) => i !== index);
    setUploadingFiles(updatedFiles);
  };

  useEffect(() => {
    updateMasterPrompt();
  }, [
    className,
    assignmentName,
    pointSystem,
    grade,
    essayType,
    awardPoints,
    customInstructions,
  ]);

  return (
    <main className={styles.container}>
      <Sidebar />
      <div className={styles.contentWrapper}>
        <section className={styles.leftColumn}>
          <form className={styles.formGroup}>
            <InputField
              label="Class Name"
              placeholder="Class name..."
              onChange={(e) => setClassName(e.target.value)}
            />
            <InputField
              label="Assignment Name"
              placeholder="Assignment name..."
              onChange={(e) => setAssignmentName(e.target.value)}
            />
          </form>
          <section>
            <h2 className={styles.sectionTitle}>
              Point System (Total) (Selected by default)
            </h2>
            <div className={styles.pointSystemContainer}>
              <button
                className={styles.activePointOption}
                onClick={() => setPointSystem("36 Points")}
              >
                36 Points
              </button>
            </div>
          </section>
          <section>
            <h2 className={styles.sectionTitle}>Grade (Select 1)</h2>
            <OptionGroup
              options={["P5", "P6"]}
              activeOption={grade}
              onChange={setGrade}
            />
          </section>
          <section>
            <h2 className={styles.sectionTitle}>
              Essay Type (For "others", please specify in custom instructions)
              (Select 1)
            </h2>
            <OptionGroup
              options={["Descriptive", "Narrative", "Argumentative"]}
              activeOption={essayType}
              onChange={setEssayType}
            />
            <OptionGroup
              options={["Persuasive", "Analytical", "Other"]}
              activeOption={essayType}
              onChange={setEssayType}
            />
          </section>
          <section>
            <h2 className={styles.sectionTitle}>
              Award Points For (Select Multiple)
            </h2>
            <OptionGroup
              options={["Originality", "Creativity", "Engagement"]}
              activeOptions={awardPoints}
              onChange={setAwardPoints}
              multiple={true}
            />
            <OptionGroup
              options={["Advanced Vocabulary", "Depth of Analysis"]}
              activeOptions={awardPoints}
              onChange={setAwardPoints}
              multiple={true}
            />
          </section>
          <section>
            <DropdownSelector
              label="Select Essay Question (Optional)"
              options={essayQuestions}
              value={essayQuestion}
              onChange={(selectedValue) => {
                setEssayQuestion(selectedValue);
                console.log(selectedValue);
              }}
            />
          </section>
          <section>
            <h2 className={styles.sectionTitle}>Custom Instructions</h2>
            <div className={styles.customInstructions}>
              <textarea
                className={styles.instructionsText}
                placeholder={`Custom Grading Instructions:\n- Details about the assignment\n- Your personal grading style`}
                onChange={(e) => setCustomInstructions(e.target.value)}
              />
            </div>
          </section>
          <button
            className={`${styles.startGradingButton} ${isUploading ? styles.disabledButton : ""}`}
            onClick={handleSubmit}
            ref={loading}
            disabled={isUploading}
          >
            {isUploading ? "Uploading... (Don't Refresh)" : "Start Grading"}
          </button>
        </section>
        <section className={styles.rightColumn}>
          <FileUploader
            setUploadingFiles={setUploadingFiles}
            uploadingFiles={uploadingFiles}
            removeUploadFiles={removeUploadFiles}
          />
        </section>
      </div>
    </main>
  );
};

export default GradingSystem;