import React, { useEffect, useState } from "react";
import styles from "../../pages/Upload.module.css";
import uploadIcon from "../../assets/cloud-upload.svg";
import fileIcon from "../../assets/file.svg";
import trashCan from "../../assets/trash-can.svg";

const FileUploader = ({
  uploadingFiles,
  setUploadingFiles,
  removeUploadFiles,
}) => {
  const [files, setFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState([]);
  const [progressStart, setProgressStart] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles([...files, ...droppedFiles]);
    setFilesLoading(droppedFiles);
    handleUpload(droppedFiles);
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
    setFilesLoading(selectedFiles);
    handleUpload(selectedFiles);
  };

  const removeFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    removeUploadFiles(index);
  };

  const startProgress = () => {
    setProgressStart(true);
  };

  /////// handleUpload with student name extract from file name
  const handleUpload = async (selectedFiles) => {
    try {
      startProgress();
      setUploadingFiles([...uploadingFiles, ...selectedFiles]);
      setProgressStart(false);
      setProgressValue(100);
      setFilesLoading([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!progressStart) return;

    setProgressValue(0);
    const timer = setInterval(() => {
      setProgressValue((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(timer);
          return 100;
        }
        return prevProgress + 2; // Change this value to control the speed
      });
    }, 1000); // Change this value to control the frequency

    return () => {
      clearInterval(timer);
    };
  }, [progressStart]);

  return (
    <div className={styles.fileUploader}>
      <div
        className={styles.dropZone}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {/* Always render drop zone icon & text */}
        <img
          src={uploadIcon}
          alt="Upload icon"
          className={styles.dropZoneIcon}
        />
        <div className={styles.dropZoneText}>Drag and Drop here</div>
        <div className={styles.dropZoneReminderText}>
          (please name file with name of the student)
        </div>
        <div className={styles.dropZoneSubtext}>or</div>
        <label className={styles.selectFileButton}>
          Select file
          <input
            type="file"
            onChange={handleFileSelect}
            className={styles.visuallyHidden}
            multiple
            accept="pdf"
          />
        </label>

        {/* Add spacing by wrapping files in a styled container */}
        {files.length > 0 && (
          <div className={styles.fileListContainer}>
            <div className={styles.fileList}>
              {files.map((file, index) => (
                <div key={index} className={styles.fileItem}>
                  <img
                    src={fileIcon}
                    alt="File icon"
                    className={styles.fileIcon}
                  />
                  <div className={styles.fileDetails}>
                    <div className={styles.fileName}>{file.name}</div>
                    <div className={styles.fileProgress}>
                      <div className={styles.fileProgressTotal} />
                      <div
                        className={styles.fileProgressValue}
                        style={{
                          width: filesLoading.includes(file)
                            ? progressValue + "%"
                            : "100%",
                        }}
                        hidden={progressValue === 0}
                      />
                    </div>
                  </div>
                  <img
                    src={trashCan}
                    alt="Delete icon"
                    className={styles.deleteIcon}
                    onClick={() => removeFile(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}


export default FileUploader;
