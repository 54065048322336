import React, { useState } from 'react';
import styles from './PricingSection.module.css';

const PricingSection = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');

  const plans = [
    {
      id: 1,
      title: "Basic",
      price: billingCycle === 'monthly' ? "$13" : "$10",
      description: "Best for trying out the system. Up to 10 essays per month",
      features: [
        "Up to 5 essays per month",
        "Custom rubrics",
        "File storage",
        "Basic dashboard"
      ],
      monthlyPaymentLink: "https://buy.stripe.com/cN29Eq17L2gF6iscMN",
      yearlyPaymentLink: "https://buy.stripe.com/3cs03QbMp8F3gX6eUY"
    },
    {
      id: 2,
      title: "Advanced",
      price: billingCycle === 'monthly' ? "$24" : "$19",
      description: "Best for individual private tutor. Up to 20 essays per month",
      features: [
        "Up to 20 essays per month",
        "Custom rubrics",
        "File storage",
        "Basic dashboard",
        "Drawing and typing on essays"
      ],
      monthlyPaymentLink: "https://buy.stripe.com/8wMeYK6s5cVj22c144",
      yearlyPaymentLink: "https://buy.stripe.com/5kA3g2dUx7AZ9uEfZ3"
    },
    {
      id: 3,
      title: "Premium",
      price: billingCycle === 'monthly' ? "$40" : "$32",
      description: "School teacher with bigger class. Up to 40 essays per month",
      features: [
        "Up to 40 essays per month",
        "Custom rubrics",
        "File storage",
        "Advanced reporting & analytics",
        "Drawing and typing on essays",
        "Stack unused credits to next month"
      ],
      monthlyPaymentLink: "https://buy.stripe.com/4gw7wi8AdaNbfT2eUW",
      yearlyPaymentLink: "https://buy.stripe.com/5kA3g2dUx7AZ9uEfZ3"
    },
    {
      id: 4,
      title: "Professional",
      price: billingCycle === 'monthly' ? "$72" : "$57",
      description: (
        <>
          <strong>Professionals with multiple classes.</strong>
          <br />
          Up to 80 essays per month
        </>
      ),
      features: [
        "Up to 80 essays per month",
        "Custom rubrics",
        "File storage",
        "Advanced reporting & analytics",
        "Drawing and typing on essays",
        "Stack unused credits to next month"
      ],
      monthlyPaymentLink: "https://buy.stripe.com/7sI4k6aIl2gFgX69AD",
      yearlyPaymentLink: "https://buy.stripe.com/fZe5oa3fTbRfeOYaEL"
    }
  ];

  return (
    <div className={styles["pricing-section"]}>
      <h2 className={styles["section-title"]}>Plans for Your Need</h2>
      <p className={styles["section-description"]}>
        Select from the best plan, ensuring a perfect match. Need more or less? Customize your subscription for a seamless fit!
      </p>

      <div className={styles["billing-toggle"]}>
        <button 
          className={`${styles["billing-button"]} ${billingCycle === 'monthly' ? styles["active"] : ''}`}
          onClick={() => setBillingCycle('monthly')}
        >
          Monthly
        </button>
        <button 
          className={`${styles["billing-button"]} ${billingCycle === 'annually' ? styles["active"] : ''}`}
          onClick={() => setBillingCycle('annually')}
        >
          Annually (20% OFF)
        </button>
      </div>

      <div className={styles["plans-container"]}>
        {plans.map((plan) => (
          <PlanCard 
            key={plan.id}
            plan={plan}
            billingCycle={billingCycle}  // Pass billingCycle as a prop
            isHighlighted={plan.title === "Premium"}  // Only highlight Premium
          />
        ))}
      </div>

      <p className={styles["footer-text"]}>
        All plans come with a 7-day free trial and 5 free credits <br />
        Are you a school? <a href="#" className={styles["contact-link"]}>Contact us</a> to get a better quote. <br />
        <em>* Feature releasing soon</em>
      </p>
    </div>
  );
};

const PlanCard = ({ plan, billingCycle, isHighlighted }) => {
  const paymentLink = billingCycle === 'monthly' ? plan.monthlyPaymentLink : plan.yearlyPaymentLink;

  return (
    <div 
      className={`${styles["plan-card"]} ${isHighlighted ? styles["highlighted"] : ''}`}
    >
      <div className={styles["plan-heading"]}>
        <h3 className={styles["plan-title"]}>{plan.title}</h3>
        <p className={styles["description"]}>{plan.description}</p>
      </div>

      <div className={styles["plan-pricing"]}>
        <p className={styles["price"]}>
          <span className={styles["price-amount"]}>{plan.price}</span>
          <span className={styles["price-per"]}> / month</span>
        </p>
        <a href={paymentLink} className={styles["free-trial-button"]} target="_blank" rel="noopener noreferrer">
          Start Free Trial
        </a>
      </div>

      <div className={styles["plan-features"]}>
        <h4 className={styles["features-heading"]}>Features</h4>
        <ul className={styles["features-list"]}>
          {plan.features.map((feature, index) => (
            <li key={index} className={styles["feature-item"]}>
              <span className={styles["tick-circle"]}>✔</span>
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PricingSection;
