import React from "react";
import styles from '../../pages/GradedEssay.module.css';

function RubricsModal({ languageScore, contentScore, onClose }) {
  return (
    <div className={styles.RubricmodalOverlay}>
      <div className={styles.RubricmodalContent}>
        <button className={styles.closeButtonRubric} onClick={onClose}>
          &times;
        </button>
        <div className={styles.rubricContainer}>
          <h1> Grading Rubric </h1>
          <table>
            <thead>
              <tr>
                <th>Mark Range</th>
                <th>Content (18 Marks)</th>
                <th>Language (18 Marks)</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.markRange} id="range-16-18">
                <td>16 - 18</td>
                <td className={contentScore >= 16 ? styles.highlight : ""}>
                  <ul>
                    <li> Ideas are <strong>well-developed</strong></li>
                    <li> <strong>Very good</strong> organization</li>
                  </ul>
                </td>
                <td className={languageScore >= 16 ? styles.highlight : ""}>
                  <ul>
                    <li> Effective word choice </li>
                    <li> Smooth flow of ideas with a variety of </li>
                    <li> sentence structures  </li>
                    <li> Very good use of language conventions </li>
                  </ul>
                </td>
              </tr>
              <tr className={styles.markRange} id="range-13-15">
                <td>13 - 15</td>
                <td className={contentScore >= 13 && contentScore <= 15 ? styles.highlight : ""}>
                  <ul>
                    <li> Ideas are sufficiently developed </li>
                    <li> Good organisation </li>
                  </ul>
                </td>
                <td className={languageScore >= 13 && languageScore <= 15 ? styles.highlight : ""}>
                  <ul>
                    <li> Adequate word choice </li>
                    <li> Adequate variety of sentence structures </li>
                    <li> Good use of language conventions </li>
                  </ul>
                </td>
              </tr>
              <tr className={styles.markRange} id="range-9-12">
                <td>9 - 12</td>
                <td className={contentScore >= 9 && contentScore <= 12 ? styles.highlight : ""}>
                  <ul>
                    <li> Ideas are generally developed </li>
                    <li> Some instances of organisation </li>
                  </ul>
                </td>
                <td className={languageScore >= 9 && languageScore <= 12 ? styles.highlight : ""}>
                  <ul>
                    <li> Fairly adequate word choice </li>
                    <li> Fairly adequate use of a variety of sentence </li>
                    <li> structures </li>
                    <li> Fairly adequate use of language conventions </li>
                  </ul>
                </td>
              </tr>
              <tr className={styles.markRange} id="range-5-8">
                <td>5 - 8</td>
                <td className={contentScore >= 5 && contentScore <= 8 ? styles.highlight : ""}>
                  <ul>
                    <li> Ideas are of some relevance </li>
                    <li> A few instances of organisation </li>
                  </ul>
                </td>
                <td className={languageScore >= 5 && languageScore <= 8 ? styles.highlight : ""}>
                  <ul>
                    <li> Simple word choice </li>
                    <li> Simple sentence fluency </li>
                    <li> Some attempt at using language conventions </li>
                  </ul>
                </td>
              </tr>
              <tr className={styles.markRange} id="range-1-4">
                <td>1 - 4</td>
                <td className={contentScore >= 1 && contentScore <= 4 ? styles.highlight : ""}>
                  <ul>
                    <li> A slight attempt at developing ideas </li>
                    <li> A slight attempt at organisation </li>
                  </ul>
                </td>
                <td className={languageScore >= 1 && languageScore <= 4 ? styles.highlight : ""}>
                  <ul>
                    <li> A few instances of simple word choice </li>
                    <li> A few instances of simple sentence fluency </li>
                    <li> A slight attempt at using language </li>
                    <li> conventions </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default RubricsModal;