import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import "../App.css";
import logo from '../assets/lianhua-full-logo.png';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        localStorage.setItem(
          "name",
          userCredential?.user?.displayName || "No Name"
        );
        localStorage.setItem("email", userCredential?.user?.email);
        localStorage.setItem("accessToken", userCredential?.user?.accessToken);
        localStorage.setItem("uid", userCredential?.user?.uid);
        navigate("/BulkUpload");
      })
      .catch((error) => {
        console.log(error);
        setError("Wrong Username or password");
      });
  };

  const resetToken = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("uid");
  };

  useEffect(() => {
    resetToken();
  }, []);

  return (
    <div className={styles["div-form"]}>
      <form onSubmit={handleSubmit} className={styles["login-form"]}>
        <img src={logo} alt="Logo" className={styles["login-logo"]} />
        <input
          className={styles["loginInputField"]}
          type="text"
          placeholder="Username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          className={styles["loginInputField"]}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button className={styles["login-button"]} type="submit">
          Log In
        </button>

        {error && <p className={styles["error-message"]}>{error}</p>}

        <div className={styles["login-links"]}>
          <a href="/ForgotPassword" className={styles["forgot-password"]}>
            Forgot password?
          </a>
          <a href="/SignUp" className={styles["signup-link"]}>
            Sign up
          </a>
        </div>
      </form>
    </div>
  );
}

export default Login;
