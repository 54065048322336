import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewIcon from "../assets/view.svg";
import TrashCanIcon from "../assets/trash-can.svg";
import sortIcon from "../assets/sort.svg";
import EditIcon from "../assets/pencil.svg"
import styles from "./Table.module.css";

export function Table({ assignments, onDelete }) {
  const [sortOrder, setSortOrder] = useState("asc");

  const rowHeight = 50;
  const tableHeight = assignments.length * rowHeight;

  if (!Array.isArray(assignments)) {
    return <div>No assignments available</div>;
  }

  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    assignments.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return newSortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

  return (
    <table className={styles["assignment-table"]} style={{ height: `${tableHeight}px` }}>
      <thead>
        <tr>
          <th>
            Upload Date
            <button onClick={handleSort} className={styles["sort-button"]}>
              <img src={sortIcon} alt="Sort" className={styles["sort-icon"]} />
            </button>
          </th>
          <th>Assignment Name</th>
          <th>Student Name</th>
          <th>Class</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {assignments.map((assignment, index) => (
          <tr key={index} style={{ height: `${rowHeight}px` }}>
            <td>{new Date(assignment.createdAt).toLocaleDateString()}</td>
            <td>{assignment.essayName}</td>
            <td>{assignment.studentName}</td>
            <td>{assignment.className}</td>
            <td
              className={
                assignment.processState === "processed"
                  ? styles["completed"]
                  : assignment.processState === "processing"
                    ? styles["processing"]
                    : styles["failed"]
              }
            >
              {assignment.processState === "processed"
                ? "Completed"
                : assignment.processState === "processing"
                  ? "Processing"
                  : "Failed"}
            </td>
            <td>
              <a
                href="#delete"
                onClick={() => {
                  console.log(assignment._id);
                  onDelete(assignment._id);
                }}
              >
                <img src={TrashCanIcon} alt="Delete" className={styles["view-icon"]} />
              </a>{" "}
              {assignment.processState === "processed" && (
                <>
                  <Link
                    to={`/GradedEssay/${assignment._id}`}
                    state={{ Assignment: assignment }}
                  >
                    <img src={ViewIcon} alt="View" className={styles["view-icon"]} />
                  </Link>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}



export function QuestionTable({questions, onEdit, onDelete}) {
  const handleSort = () => {};
  const rowHeight = 50;
  const maxLengthBody = 250; 
  function truncateBody(content) {
    return (content.length > maxLengthBody) ? content.slice(0, maxLengthBody - 1) + '…' : content;
  }
  return (
    <table className={styles["assignment-table"]} style={{ height: `${questions.length * rowHeight}px` }}>
      <thead>
        <tr>
          <th width="15%">
            Creation Date{" "}
            <button onClick={handleSort} className={styles["sort-button"]}>
              <img src={sortIcon} alt="Sort" className={styles["sort-icon"]} />
            </button>
          </th>
          <th width="15%">
            Question title{" "}
            <button onClick={handleSort} className={styles["sort-button"]}>
              <img src={sortIcon} alt="Sort" className={styles["sort-icon"]} />
            </button>
          </th>
          <th width="50%">Question Content </th>
          <th width="10%">Delete</th>
          <th width="10%">Edit</th>
        </tr>
      </thead>
      <tbody>
        {questions.map((question, index) => (
            <tr key={index} style={{ height: `${rowHeight}px` }}>
              <td>{new Date(question.createdAt).toLocaleDateString()}</td>
              <td>{question.topic}</td>
              <td>{truncateBody(question.instruction)}</td>
              <td><a
                onClick={() => {
                  console.log(question._id);
                  onDelete(question._id);
                }}
              >
                <img src={TrashCanIcon} alt="Delete" className={styles["view-icon"]} />
              </a>{" "}</td>
              <td><a
                onClick={() => {
                  console.log(question._id);
                  onEdit(question._id);
                }}
              >
                <img src={EditIcon} alt="Edit" className={styles["view-icon"]} />
              </a>{" "}</td>
            </tr>
        ))}
      </tbody>
    </table>
  );
}

export default { QuestionTable, Table };
