import Sidebar from '../components/Sidebar';
import React, { useEffect, useState, useRef, useCallback } from "react";
import {QuestionTable} from "../components/Table";
import styles from './QuestionUpload.module.css';
import filterIcon from "../assets/filter.svg";
import { useUser } from "../UserContext";
import { deleteQuestion, questionList } from "../API/api";
import EssayPromptModal from '../components/question_upload_page/EssayPromptModal';
import EditEssayPromptModal from '../components/question_upload_page/EditEssayPromptModal';

function QuestionUpload() {
    const user = useUser();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [search, setSearch] = useState("");
    const [questions, setQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    

    // add questions
    const [showEssayPrompt, setShowEssayPrompt] = useState(false);
    const [showEditEssayPrompt, setShowEditEssayPrompt] = useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null); // null | id
    // TODO: Add fetch page number
    
      // Create refs for state variables
    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const searchRef = useRef(search);
    const currentPageRef = useRef(currentPage);

      // Update refs whenever state changes
    useEffect(() => {
        startDateRef.current = startDate;
    }, [startDate]);

    useEffect(() => {
        endDateRef.current = endDate;
    }, [endDate]);

    useEffect(() => {
        searchRef.current = search;
    }, [search]);

    useEffect(() => {
    currentPageRef.current = currentPage;
      }, [currentPage]);

    useEffect(() => {
        fetchQuestionList();
    }, [currentPage]);

    useEffect(() => {
        const intervalId = setInterval(() => {
          fetchQuestionList();
        }, 3000);
      
        return () => clearInterval(intervalId);
    }, []);

    const fetchQuestionList = useCallback(async () => {
        const uid = localStorage.getItem("uid");
        try {
            const result = await questionList(currentPage, {
                startDate: startDateRef.current,
                endDate: endDateRef.current,
                search: searchRef.current,
                uid
            });
            setQuestions(result.body);
        } catch (error) {
            console.error("Error fetching document list:", error);
        }
    }, [])

    const handleFilterClick = () => {
        fetchQuestionList();
    }
    const onDelete = async (id) => {
        try {
        if (window.confirm("Do you really want to delete this document?")) {
            await deleteQuestion(id);
            fetchQuestionList();
        }
        } catch (error) {
        console.log(error);
        }
    };
    
    const onEdit = async (id) => {
        try {
            setEditQuestionId(id);
            setShowEditEssayPrompt(true);
            fetchQuestionList();
        } catch (error) {
            console.log(error);
        }  
    };

    return (
    <div className = {styles['Question-Upload']}>
        <Sidebar />
        
        <div className={styles['Question-Upload-Main']}>
            <div className={styles["date-filter-section"]}>
                <div className={styles["start-date-filter"]}>
                    <div> Start Date </div>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className={styles["date-input"]}
                    />
                </div>
            <div className={styles["end-date-filter"]}>
                <div> End Date </div>
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    placeholder="End Date"
                    className={styles["date-input"]}
                />
            </div>
            <div className="search-box-component">
                <div> Search </div>
                <input
                className={styles["search-box"]}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search topic"
                />
            </div>
            <button className={styles["filter-button"]} onClick={handleFilterClick}>
                <img src={filterIcon} alt="Filter" className={styles["filter-icon"]}/> Filter
            </button>
            <button onClick={() => setShowEssayPrompt(true)} className={styles["add-question-button"]}>
                +
            </button>
            </div>
            <div className={styles["table-container"]}>
                <QuestionTable questions={questions} onEdit={onEdit} onDelete={onDelete}/>
            </div>
            {showEssayPrompt && <EssayPromptModal onClose={() => setShowEssayPrompt(false)}/>}
            {showEditEssayPrompt && <EditEssayPromptModal id={editQuestionId}  onClose={() => setShowEditEssayPrompt(false)}/>}
        </div>
        
    </div>
    )
}

export default QuestionUpload;