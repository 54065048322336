import styles from'./TeacherDashboard.module.css';
import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import Sidebar from '../components/Sidebar';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

import { numberEssayGraded, marksEssayGraded } from '../API/api'

ChartJS.register(
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  Filler,
  Tooltip,
  Legend
);

function TeacherDashboard() {
  const [totalEssays, setTotalEssays] = useState();
  const [filledData, setFilledData] = useState();
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    const fetchTotalEssays = async () => {
      try {
        const res = await numberEssayGraded();
        setTotalEssays(res);
      } catch (error) {
        console.log('Error fetching total essays:', error);
      }
    };
  
    fetchTotalEssays();
  }, []);
  
  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const res = await marksEssayGraded();
        console.log("Marks Assignment:", res)
        setAssignments(res);
      } catch (error) {
        console.log('Error fetching assignments:', error);
      }
    };
  
    fetchAssignments();
  }, []);

   useEffect(() => {
    const getDateNDaysAgo = (days) => {
      const today = new Date();
      const dateNDaysAgo = new Date(today.setDate(today.getDate() - days));
      return dateNDaysAgo;
    };

    const getDateRange = (startDate, endDate) => {
      const dateArray = [];
      let currentDate = new Date(startDate);
    
      while (currentDate <= endDate) {
        dateArray.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1); // Correct date increment
      }
      return dateArray;
    };

    const fillDateRangeWithEssays = async () => {
      const startDate = getDateNDaysAgo(6);
      const endDate = new Date();
      const dateRange = getDateRange(startDate, endDate);

      const NumOfEssaysData = {};
      dateRange.forEach((date) => {
        NumOfEssaysData[date] = totalEssays[date] || 0;
      });

      setFilledData(NumOfEssaysData);
    };

    if (totalEssays) {
      fillDateRangeWithEssays();
    }
  }, [totalEssays]);
  
  const optionsMarks = {
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear',
        grace: '20%'
      }
    },
    plugins: {
      legend: {
        position: 'top',
      }
    },
  };

  const optionsNum = {
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear',
        grace: '20%',
        min: 0,
        
      }
    },
    plugins: {
      legend: {
        position: 'top',
        title: {
          position: 'end',
        }
      }
    },
  };
  const dataMarks = {
    labels: assignments ? assignments.map(item => item.assignmentName) : [],
    
    datasets: [{
        label: 'Average Score',
        data: assignments ? assignments.map(item => item.averageScore) : [],
        fill: false,
        borderColor: '#5388D8',
        backgroundColor: '#5388D8',
        borderWidth: 1,
        tension: 0
    }]
};


  const dataNumOfEssays = {
    labels: filledData? Object.keys(filledData) : [],
    
    datasets: [{
      label: 'Number of Essays',
      data: filledData ? Object.keys(filledData).map(date => filledData[date]) : [],
      fill: true,
      borderColor: '#F4BE37',
      backgroundColor: 'rgba(244, 190, 55, 0.5)',
      tension: 0.2,
      borderWidth: 1,
    }]
  };

  return (
    <div className={styles["Page"]}>
      <Sidebar />
      <div className={styles["container"]}>
        <h3>All Classes Summary</h3>
        <div className={styles["text-title"]}>Assignment Performance</div>
        <div className={styles["text-body"]}>Average performance for all classes for past assignments</div>
        <div className={styles['chart-container']}>
          <Line options={optionsMarks} data={dataMarks} />
        </div>
        <div className={styles["text-title"]}>Number of Essays Graded</div>
        <div className={styles["text-body"]}>How many total amount of essays graded each day</div>
        <div className={styles["chart-container"]}>
          <Line options={optionsNum} data={dataNumOfEssays} />
        </div>
      </div>
    </div>
  );
}

export default TeacherDashboard;
