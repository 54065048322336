/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./UploadHistory.module.css";
import { Table } from "../components/Table";
import Sidebar from "../components/Sidebar";
import filterIcon from "../assets/filter.svg";
import { fileUploadPageNumber, documentList, documentDelete } from "../API/api";
import { useUser } from "../UserContext";

function UploadHistory() {
  const user = useUser();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [assignments, setAssignments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchPageNumber = async () => {
    try {
      const result = await fileUploadPageNumber({ startDate, endDate, search });
      setTotalPages(result.count);
    } catch (error) {
      console.error("Error fetching page number:", error);
    }
  };

  useEffect(() => {
    fetchPageNumber();
  }, [user?.uid]);

  const fetchDocumentList = async () => {
    const uid = localStorage.getItem("uid");
    try {
      const result = await documentList(currentPage, {
        startDate,
        endDate,
        search,
        uid
      });
      setAssignments(result.body);
    } catch (error) {
      console.error("Error fetching document list:", error);
    }
  };

  useEffect(() => {
    fetchDocumentList();
  }, [currentPage]);

  const handlePageChange = async (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const onDelete = async (id) => {
    try {
      if (window.confirm("Do you really want to delete this document?")) {
        await documentDelete(id);
        fetchDocumentList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterClick = () => {
    fetchDocumentList();
    fetchPageNumber();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        assignments.find(
          (assignment) => assignment.processState === "processing"
        )
      )
        fetchDocumentList();
    }, 3000);
    return () => clearInterval(interval);
  }, [assignments]);

  return (
    <div className={styles["Upload-History"]}>
      <Sidebar />
      <div className={styles["Upload-History-Main"]}>
        <div className={styles["date-filter-section"]}>
          <div className={styles["start-date-filter"]}>
            <div> Start Date </div>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={styles["date-input"]}
            />
          </div>
          <div className={styles["end-date-filter"]}>
            <div> End Date </div>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
              className={styles["date-input"]}
            />
          </div>
          <div className={styles["search-box-component"]}>
            <div> Search </div>
            <input
              className={styles["search-box"]}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
            />
          </div>
          <button className={styles["filter-button"]} onClick={handleFilterClick}>
            <img src={filterIcon} alt="Filter" className={styles["filter-icon"]} /> Filter
          </button>
        </div>
        <div className={styles["table-container"]}>
          <Table assignments={assignments} onDelete={onDelete} />
        </div>
        <div className={styles["pagination"]}>
          <button
            className={`${styles["page-button"]} ${currentPage === 1 ? styles["disabled"] : ""}`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {"<"}
          </button>

          {/* First Page Button */}
          <button
            className={`${styles["page-button"]} ${currentPage === 1 ? styles["active"] : ""}`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>

          {/* Ellipsis Before Current Page */}
          {currentPage > 3 && <span className={styles["ellipsis"]}>...</span>}

          {/* Render Nearby Page Buttons */}
          {[...Array(totalPages)].map((_, index) => {
            const pageNumber = index + 1;
            if (
              pageNumber !== 1 &&
              pageNumber !== totalPages &&
              (pageNumber === currentPage ||
                pageNumber === currentPage - 1 ||
                pageNumber === currentPage + 1)
            ) {
              return (
                <button
                  key={pageNumber}
                  className={`${styles["page-button"]} ${currentPage === pageNumber ? styles["active"] : ""}`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              );
            }
            return null;
          })}

          {/* Ellipsis After Current Page */}
          {currentPage < totalPages - 2 && <span className={styles["ellipsis"]}>...</span>}

          {/* Last Page Button */}
          <button
            className={`${styles["page-button"]} ${currentPage === totalPages ? styles["active"] : ""}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>

          {/* Next Page Button */}
          <button
            className={`${styles["page-button"]} ${currentPage === totalPages ? styles["disabled"] : ""}`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadHistory;
