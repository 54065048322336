// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height:100%;
  width: 100%;
  background: white;
  color: white;
  font-family: 'Poppins', san-serif;
}

.App{
  min-height: 100vh;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE;AACF;;AAEA;EACE,SAAS;EACT,UAAU;EACV,WAAW;AACb;;AAEA;EACE,SAAS;EACT,UAAU;EACV,WAAW;EACX,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf","sourcesContent":["* {\r\n  margin: 0;\r\n  padding: 0;\r\n  box-sizing: border-box;\r\n}\r\n\r\nhtml {\r\n  font-size: 62.5%\r\n}\r\n\r\n#root {\r\n  margin: 0;\r\n  padding: 0;\r\n  width: 100%;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  padding: 0;\r\n  height:100%;\r\n  width: 100%;\r\n  background: white;\r\n  color: white;\r\n  font-family: 'Poppins', san-serif;\r\n}\r\n\r\n.App{\r\n  min-height: 100vh;\r\n  display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
