import React from 'react';
import styles from './Sidebar.module.css';
import logout_image from '../assets/logout.svg';
import file_upload_image from '../assets/file-upload.svg';
import file_history_image from '../assets/clock.svg';
import teacher_dashboard_image from '../assets/dashboard.svg';
import question_upload_logo from '../assets/question-mark-icon.svg';
import feedback_image from '../assets/feedback.svg';
import school_logo from '../assets/lianhua-square-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from "../firebase";
import { signOut } from "firebase/auth";;

function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/Login');
      })
      .catch((error) => {
        console.log(error);
        // Optionally, display an error message to the user
      });
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles['sidebar-logo']}>
        <img src={school_logo} alt="School Logo" />
      </div>
      <div className={styles['centre-buttons']}>
        <Link to="/BulkUpload" className={styles['sidebar-button']} title="Upload Essays">
          <img src={file_upload_image} alt="Bulk Upload" />
        </Link>
        <Link to="/QuestionUpload" className={styles['sidebar-button']} title="Essay Prompt Questions">
          <img src={question_upload_logo} alt="Question Upload" />
        </Link>
        <Link to="/Upload-History" className={styles['sidebar-button']} title="History">
          <img src={file_history_image} alt="File Upload History" />
        </Link>
        <Link to="/TeacherDashboard" className={styles['sidebar-button']} title="Dashboard">
          <img src={teacher_dashboard_image} alt="Teacher Dashboard" />
        </Link>
      </div>
      <button
        className={styles['sidebar-button']}
        onClick={() => window.open('https://forms.gle/FwwZhce4kBQ1D1iW7', '_blank')}
        title="Feedback / Bug Report"
      >
        <img src={feedback_image} alt="Feedback" />
      </button>
      <button className={styles['sidebar-button']} onClick={handleLogout} title="Logout">
        <img src={logout_image} alt="Logout" />
      </button>
    </div>
  );
}

export default Sidebar;
