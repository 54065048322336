import React, { useEffect, useState } from "react";
import styles from "../../pages/QuestionUpload.module.css";
import uploadIcon from "../../assets/cloud-upload.svg";
import trashCan from "../../assets/trash-can.svg";

export const FileUploader = ({
  uploadingFiles,
  setUploadingFiles,
  removeUploadFiles,
  allowedFileTypes = ["jpg", "png", "pdf", "docx"], 
}) => {
  const [files, setFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState([]);
  const [progressStart, setProgressStart] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const filteredFiles = droppedFiles.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedFileTypes.includes(extension)) {
        alert(`Please only upload files of type: ${allowedFileTypes.join(", ")}`);
        return false;
      }
      return true;
    });

    setFiles([...files, ...filteredFiles]);
    setFilesLoading(filteredFiles);
    handleUpload(filteredFiles);
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);

    const filteredFiles = selectedFiles.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedFileTypes.includes(extension)) {
        alert(`Please only upload files of type: ${allowedFileTypes.join(", ")}`);
        return false;
      }
      return true;
    });

    setFiles([...files, ...filteredFiles]);
    setFilesLoading(filteredFiles);
    handleUpload(filteredFiles);
  };

  const removeFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    removeUploadFiles(index);
  };

  const startProgress = () => {
    setProgressStart(true);
  };

  /////// handleUpload with student name extract from file name
  const handleUpload = async (selectedFiles) => {
    try {
      startProgress();
      setUploadingFiles([...uploadingFiles, ...selectedFiles]);
      setProgressStart(false);
      setProgressValue(100);
      setFilesLoading([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!progressStart) return;

    setProgressValue(0);
    const timer = setInterval(() => {
      setProgressValue((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(timer);
          return 100;
        }
        return prevProgress + 2; // Change this value to control the speed
      });
    }, 1000); // Change this value to control the frequency

    return () => {
      clearInterval(timer);
    };
  }, [progressStart]);

  return (
    <div className={styles.fileUploader} >
        {files.length > 0 && (
          <div className={styles.fileList}>
            {files.map((file, index) => (
              <div key={index} className={styles.fileItem}>
                <div>{file.name}</div>
                <img
                  src={trashCan}
                  alt="Delete icon"
                  className={styles.deleteIcon}
                  onClick={() => removeFile(index)}
                />
              </div>
            ))}
          </div>
        )}
      <div
        className={styles.dropZone}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        // onClick={handleClick}
      >
        <img
          src={uploadIcon}
          alt="Upload icon"
          className={styles.dropZoneIcon}
        />
        <div className={styles.dropZoneText}>
          {"    "}Drag and Drop here
        </div>
       
        <div className={styles.dropZoneSubtext}>or</div>
        <label className={styles.selectFileButton}>
          Select file
          <input
            type="file"
            onChange={handleFileSelect}
            className={styles.visuallyHidden}
            multiple
            accept="pdf"
            // ref={fileInputRef}
          />
        </label>
        
      </div>
    </div>
  );
};


// chip component for already uploaded files
export const FileURLUploader = ({
  uploadingFilesURL,
  removeUploadFilesURL,
}) => {
  return (
    <div className={styles.fileList}>
      {uploadingFilesURL.map((fileURL, index) => {
        return (
          <div key={index} className={styles.fileItem}>
            <a href={fileURL} style={{ textDecoration: "none" }} target="_blank">
              <div>
                🔗 {fileURL.replace(/^.*[\\/]/, '').replace(/^(.{11}[^\s]*).*/, "$1")} {/* Simplify display file name from file URL */}
              </div>
            </a>
            <img
              src={trashCan}
              alt="Delete icon"
              className={styles.deleteIcon}
              onClick={() => removeUploadFilesURL(index)}
            />
          </div>
        );
      })}
    </div>
  );
}; // implement delete feature

