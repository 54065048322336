// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerifyUser_verify-user-container__sYTck {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    text-align: center;
  }
  
  .VerifyUser_verify-user-container__sYTck h2 {
    font-size: 24px;
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/VerifyUser.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":[".verify-user-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f8f9fa;\n    text-align: center;\n  }\n  \n  .verify-user-container h2 {\n    font-size: 24px;\n    color: #333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verify-user-container": `VerifyUser_verify-user-container__sYTck`
};
export default ___CSS_LOADER_EXPORT___;
